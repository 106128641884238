var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('transition', {
    attrs: {
      "name": "transition-fade-up"
    }
  }, [_vm.showForm ? _c('v-card', {
    staticClass: "rounded-xl mx-auto",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "elevation": "20"
    }
  }, [_c('v-row', {
    staticClass: "text-center d-flex"
  }, [_c('v-col', {
    staticClass: "pa-8 pa-lg-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "src": require("@/assets/logo.svg")
    }
  }), _c('p', {
    staticClass: "text-body-2 text--secondary my-10"
  }, [_vm._v("Choose your new password.")]), _c('v-form', {
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-text-field', {
    ref: "password",
    attrs: {
      "label": "New Password",
      "outlined": "",
      "rounded": "",
      "color": "primary",
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-btn', {
    staticClass: "primary white--text",
    attrs: {
      "type": "submit",
      "rounded": "",
      "large": "",
      "block": "",
      "loading": _vm.loading
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
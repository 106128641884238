<template>
  <v-container fill-height fluid>
    <transition name='transition-fade-up'>
          <v-card elevation=20 class='rounded-xl mx-auto' style='overflow:hidden;' v-if='showForm'>
            <v-row class='text-center d-flex'>
              <v-col cols=12 class='pa-8 pa-lg-16'>
                <v-container fill-height>
                  <v-container class='text-center'>
                    
                    <v-img src="@/assets/logo.svg" class='mx-auto' style='width:95%;' />
                    <p class='text-body-2 text--secondary my-10'>Choose your new password.</p>
                    <v-form @submit.prevent="submit" :disabled='loading'>
                      <v-text-field ref='password' v-model='password' label='New Password' outlined rounded color='primary' type='password' />
                      <v-btn type='submit' rounded large block class='primary white--text' :loading='loading'>Update</v-btn>
                    </v-form>

                  </v-container>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
    </transition>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/assets/common";

#rightPane{
  position: relative;

  #wallpaper1{
    @include wallpaper2(1, $primary);
  }

  #wallpaper2{
    @include wallpaper-expand();
    background-image: linear-gradient(to bottom right, $primary, darken($primary,20%));
    opacity:0.7;
  }

  #text{
    color: white;
    font-weight: lighter;
    opacity: 1;
    font-size: 1.9em;
    z-index: 0;
    text-shadow: 0px 0px rgba(black, 0.1);
  }
}

</style>


<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',

  components: {
  },
  data: () => ({
    showForm: false,
    password: null,
    loading: false,
  }),
  computed: {
    token(){ return this.$route.params.token; },
  },
  methods:{
    ...mapActions('UI', ['HIDE_NAVBARS', 'PROMPT', 'ALERT', 'NOTIFY', 'NAVIGATE']),
    ...mapActions('API', ['API']),
    ...mapActions('Auth', ['LOGOUT']),

    submit(){
      let data = { password: this.password };
      this.API({ method: 'POST', endpoint: `core/auth/password_reset/${this.token}`, data })
      .then(() => {
        this.ALERT({ title: 'Password Reset', message: 'Your password has been updated.' })
        .then(this.LOGOUT);
      });
    },

    validateToken(){
      this.API({ method: 'GET', endpoint: `core/auth/password_reset/${this.token}` })
      .then(() => { this.showForm = true; })
      .catch(() => {
        this.ALERT({ title: 'Password Reset', message: 'Reset link expired.' })
        .then(this.LOGOUT);
      });
    },

  },
  mounted(){
    this.HIDE_NAVBARS();
    this.validateToken();
  }
};
</script>
